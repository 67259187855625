import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1045203d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "circular h-full w-full absolute top-0 bottom-0 left-0 right-0 m-auto",
  viewBox: "25 25 50 50"
}
const _hoisted_2 = ["stroke-width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["absolute w-full -top-0.5 bg-loader", _ctx.loading ? '' : 'hidden'])
  }, [
    _createElementVNode("span", {
      class: "loader mx-auto my-0 inline-flex absolute top-1/2 left-1/2 transform -translate-x-1/2",
      style: _normalizeStyle({
        width: `${_ctx.size}px`,
      })
    }, [
      (_openBlock(), _createElementBlock("svg", _hoisted_1, [
        _createElementVNode("circle", {
          class: "path",
          cx: "50",
          cy: "50",
          r: "20",
          fill: "none",
          "stroke-width": _ctx.width,
          "stroke-miterlimit": "10"
        }, null, 8, _hoisted_2)
      ]))
    ], 4)
  ], 2))
}