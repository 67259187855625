
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import BackBtn from '@/components/BackBtn.vue';
import TheFooter from '@/components/TheFooter.vue';
import BaseLoading from '@/components/Base/BaseLoadingApp.vue';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    BackBtn,
    TheFooter,
    BaseLoading,
  },
  props: {
    isloading: {
      type: Boolean,
      default: false,
    },
    showBackBtn: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      appLoading: 'getLoading',
    }),
  },
});
