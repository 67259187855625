<template>
  <div
    class="flex text-white absolute top-1/2 left-5 transform -translate-y-1/2"
  >
    <button
      v-if="showBackBtn"
      @click="back()"
      class="
        app-header-back
        focus:outline-none
        flex
        items-center
        justify-center
        rounded-full
        bg-white
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M10.0035 4.8418L3.15771 12M3.15771 12L10.0035 19.1582M3.15771 12H20.8427"
          stroke="#191C32"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</template>
<script>
import router from '@/plugins/router';
import constRouter from '@/constants/constRouter';

export default {
  name: 'BackButton',
  props: {
    showBackBtn: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Interloan',
    },
  },
  data: () => ({
    constRouter,
  }),
  methods: {
    back() {
      router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.app-header-back {
  width: 40px;
  height: 40px;
}
</style>
