
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseLoadingApp',
  props: {
    size: {
      type: Number,
      default: 50,
    },
    width: {
      type: Number,
      default: 4,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
