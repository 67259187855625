import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-85dc0eda"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "relative header"
}
const _hoisted_2 = {
  id: "main-content",
  class: "relative flex-1 flex flex-col overflow-y-auto"
}
const _hoisted_3 = { class: "px-6 absolute w-full bottom-20" }
const _hoisted_4 = {
  key: 1,
  class: "footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_back_btn = _resolveComponent("back-btn")!
  const _component_BaseLoading = _resolveComponent("BaseLoading")!
  const _component_the_footer = _resolveComponent("the-footer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["default-layout relative", {
      'pb-14': _ctx.showFooter,
    }])
  }, [
    (_ctx.showHeader)
      ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
          _createVNode(_component_back_btn, {
            class: "z-20",
            "show-back-btn": _ctx.showBackBtn,
            title: _ctx.title
          }, null, 8, ["show-back-btn", "title"]),
          _createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "header", { class: "text-center" }, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_BaseLoading, {
      loading: _ctx.isloading || _ctx.appLoading
    }, null, 8, ["loading"]),
    _createElementVNode("main", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "bottom", {}, undefined, true)
    ]),
    (_ctx.showFooter)
      ? (_openBlock(), _createElementBlock("footer", _hoisted_4, [
          _createVNode(_component_the_footer)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}