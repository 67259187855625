<template>
  <div
    class="app-footer text-iconColor shadow-3 flex items-center justify-around"
  >
    <router-link
      :to="{ name: constRouter.DASHBOARD.name }"
      class="flex flex-col items-center w-1/4"
      active-class="active"
    >
      <icon-home />
    </router-link>
    <router-link
      :to="{ name: constRouter.OVERVIEW_HISTORY_INVESTMENT.name }"
      class="flex flex-col items-center w-1/4"
      active-class="active"
    >
      <icon-market />
    </router-link>
    <!-- <router-link
      :to="{ name: constRouter.OVERVIEW.name }"
      class="flex flex-col items-center w-1/4"
      active-class="active"
    >
      <icon-wallet />
    </router-link> -->
    <router-link
      :to="{ name: constRouter.QA.name }"
      class="flex flex-col items-center w-1/4"
      active-class="active"
    >
      <icon-question />
    </router-link>
    <router-link
      :to="{ name: constRouter.PROFILE.name }"
      class="flex flex-col items-center w-1/4"
      active-class="active"
    >
      <icon-profile />
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import constRouter from '@/constants/constRouter';
import IconHome from '@/components/Icon/IconHome.vue';
import IconMarket from '@/components/Icon/IconMarketPlace.vue';
// import IconWallet from '@/components/Icon/IconWallet.vue';
import IconProfile from '@/components/Icon/IconProfile.vue';
import IconQuestion from '@/components/Icon/IconQuestion.vue';
export default defineComponent({
  name: 'TheFooter',
  components: {
    IconHome,
    IconMarket,
    // IconWallet,
    IconProfile,
    IconQuestion,
  },
  data: () => ({ constRouter }),
});
</script>

<style scoped lang="scss">
.active {
  svg {
    stroke: #26273c;
  }
}
.app-footer {
  height: 55px;
  width: inherit;
  border-radius: 20px 20px 0 0;
  background: white;
  padding-bottom: 5px;
}

.plus-icon {
  width: 54px;
  display: flex;
  height: 54px;
  border: 2px white solid;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -22px;
}
</style>
